import React, { Component } from "react"
import firebase from "../utils/firebase"
import Layout from "../components/layout"

import Button from "@material-ui/core/Button"
import Papa from "papaparse"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import CircularProgress from "@material-ui/core/CircularProgress"
import withAuth from "../utils/with-auth"

@withAuth
class ImportPage extends Component {
  state = {
    tickets: [],
    done: false,
    loading: false,
    ticketType: null,
    eventType: null,
    error: null,
    failedImports: [],
  }
  csvFile = React.createRef()

  handleUpload() {
    const { ticketType, eventType } = this.state
    const uploadedFile = this.csvFile.current.files[0]

    if (!uploadedFile) {
      this.setState({ error: "A CSV file must be chosen!" })
      return
    }

    if (!ticketType || !eventType) {
      this.setState({ error: "A ticket type must be chosen!" })
      return
    }

    this.setState({ done: false, loading: true })
    let tickets = []
    let failedImports = []

    Papa.parse(uploadedFile, {
      worker: true,
      header: true,
      step: ({ data: { email, ...rest } }) => {
        tickets.push({ email: email.trim().toLowerCase(), ...rest })
      },
      complete: () => {
        const db = firebase.firestore()
        const ticketCollection = db.collection("tickets")
        const newTicket = {
          event: db.doc(`events/${eventType}`),
          type: db.doc(`ticketTypes/${ticketType}`),
          free: true,
        }

        const ticketRequests = tickets
          .reduce((result, currentTicket) => {
            console.warn(currentTicket)

            const possibleMatch = result.findIndex(
              ticketItem => ticketItem.email === currentTicket.email
            )

            if (possibleMatch > -1) {
              console.warn("found a match: ", currentTicket.email)

              const oldImport = result[possibleMatch]
              const newImport = {
                ...oldImport,
                mergedTickets: [...oldImport.mergedTickets, newTicket],
              }

              result[possibleMatch] = newImport
              return result
            }

            return [...result, { ...currentTicket, mergedTickets: [newTicket] }]
          }, [])
          .map(({ name, email, mergedTickets, ...rest }) => {
            if (!!name && !!email) {
              return ticketCollection
                .where("customer.email", "==", email)
                .get()
                .then(snapshot => {
                  if (!snapshot.empty) {
                    let baseTicketId = null

                    snapshot.forEach(document => {
                      const ticketDocRef = ticketCollection.doc(document.id)
                      db.runTransaction(transaction => {
                        return transaction.get(ticketDocRef).then(ticketDoc => {
                          const data = ticketDoc.data()
                          if (baseTicketId || data.mergedId) {
                            transaction.update(ticketDocRef, {
                              mergedId: baseTicketId || data.mergedId,
                            })
                          } else {
                            const newTickets = [
                              ...data.tickets,
                              ...mergedTickets,
                            ]
                            transaction.update(ticketDocRef, {
                              tickets: newTickets,
                            })
                            baseTicketId = ticketDoc.id
                          }
                        })
                      })
                    })
                  } else {
                    ticketCollection.add({
                      customer: {
                        name,
                        email,
                      },
                      notified: false,
                      synced: true,
                      tickets: mergedTickets,
                      token: "b9c03fa8-9ce8-41b4-8d16-04154864d979",
                    })
                  }
                })
                .catch(error => {
                  failedImports.push({
                    name,
                    email,
                    caught: true,
                    error: error.message,
                  })
                })
            } else {
              failedImports.push({ name, email, ...rest })
              return Promise.resolve()
            }
          })

        // Reset form as we are finished importing
        this.csvFile.current.value = ""
        this.setState({
          tickets,
          failedImports,
        })

        Promise.all(ticketRequests).then(responses => {
          this.setState({
            done: true,
            loading: false,
          })
        })
      },
    })
  }
  handleUpload = this.handleUpload.bind(this)

  handleTicketType(event) {
    const eventType = ["nLzwLw0DZPgOP1kNR8s6", "RuijbAa9la3T2WhnvHVd"].includes(
      event.target.value
    )
      ? "IkLjM64neLS9yK2iaa9j"
      : "6vM0EWLF6BPgWW1tTQ1f"
    this.setState({
      ticketType: event.target.value,
      eventType,
      error: null,
    })
  }
  handleTicketType = this.handleTicketType.bind(this)

  render() {
    const {
      loading,
      tickets,
      done,
      ticketType,
      error,
      failedImports,
    } = this.state
    return (
      <Layout
        withNav
        isLoading={loading}
        className={"list"}
        style={{ padding: "0 40px 40px", width: "100%" }}
      >
        <div style={{ display: "grid", width: "100%" }}>
          <div style={{ paddingBottom: "24px" }}>
            <h1>Import</h1>
            {error && (
              <div style={{ color: "red", marginBottom: "30px" }}>{error}</div>
            )}
            <div>
              <input style={{ width: "100%" }} type="file" ref={this.csvFile} />
            </div>
            <div style={{ width: "100%", margin: "30px 0" }}>
              <FormControl
                style={{ width: "100%" }}
                error={error && error.includes("ticket type")}
              >
                <InputLabel htmlFor="ticketType">Ticket</InputLabel>
                <Select
                  value={ticketType}
                  onChange={this.handleTicketType}
                  inputProps={{
                    name: "ticketType",
                    id: "ticketType",
                  }}
                  style={{ width: "100%" }}
                >
                  <MenuItem value={"GxjQHYEscXNpSCOvWGae"}>Standing</MenuItem>
                  <MenuItem value={"lTAQjBVLn5Cky1TpCTli"}>Seated</MenuItem>
                  <MenuItem value={"k6g197m8SdxsfHGqqTjH"}>VIP</MenuItem>
                  <MenuItem value={"RuijbAa9la3T2WhnvHVd"}>
                    Concession Pass
                  </MenuItem>
                  <MenuItem value={"nLzwLw0DZPgOP1kNR8s6"}>
                    Standard Pass
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {loading && tickets.length > 0 ? (
              <div style={{ paddingTop: "30px" }}>
                Uploading {tickets.length - failedImports.length} ticket/s
              </div>
            ) : null}
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" onClick={this.handleUpload}>
                Upload
              </Button>
            )}
            {done ? (
              <div style={{ paddingTop: "30px" }}>
                Added {tickets.length - failedImports.length} ticket/s
              </div>
            ) : null}
            {done && failedImports.length > 0 ? (
              <div style={{ paddingTop: "30px" }}>
                The following rows are missing either a name or email address:
                <pre>{JSON.stringify(failedImports, null, 2)}</pre>
              </div>
            ) : null}
          </div>
        </div>
      </Layout>
    )
  }
}

export default ImportPage
